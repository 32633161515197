'use client'

import { ClerkUpdater } from '@components/clerk-updater'
import { ProductionReactQueryDevtools } from '@components/devtools.react-query'
import { MantineProvider } from '@mantine/core'
import { ModalsProvider } from '@mantine/modals'
import { NotificationsProvider } from '@mantine/notifications'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import localFont from 'next/font/local'
import * as React from 'react'
import { PURPOSITY_ENV } from '~constants.mjs'

const sofiaBold = localFont({ src: '../assets/fonts/SofiaPro-Bold.otf' })

export default function Providers({ children }: { children: React.ReactNode }) {
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus() {
              return PURPOSITY_ENV !== 'development'
            },
          },
        },
      })
  )
  return (
    <QueryClientProvider client={queryClient}>
      {PURPOSITY_ENV === 'development' ? (
        <ReactQueryDevtools data-sentry-block />
      ) : (
        <ProductionReactQueryDevtools data-sentry-block />
      )}
      <ClerkUpdater />
      <MantineProvider
        withGlobalStyles
        withNormalizeCSS
        theme={{
          colors: {
            'purposity-blue': [
              '#D3E7ED',
              '#B1DAE6',
              '#8CD1E6',
              '#63CDEE',
              '#33CFFF',
              '#2CBBE8',
              '#2DA6CB',
              '#368EA9',
              '#3B7A8E',
              '#3D6A78',
            ],
          },
          primaryShade: 5,
          primaryColor: 'purposity-blue',
          fontFamily: 'SofiaPro-Bold, sans-serif',
          colorScheme: 'light',
          globalStyles: (/*theme*/) => ({
            body: {
              margin: 0,
              padding: 0,
              fontWeight: 500,
              WebkitFontSmoothing: 'antialiased',
              MozOsxFontSmoothing: 'grayscale',
              height: '100vh',
              width: '100vw',
              display: 'flex',
            },
            '#__next': {
              display: 'flex',
              flex: '1 1 100vw',
            },
          }),
          components: {
            Text: {
              classNames: { root: sofiaBold.className },
            },
          },
        }}
      >
        <NotificationsProvider position="top-center">
          <ModalsProvider>{children}</ModalsProvider>
        </NotificationsProvider>
      </MantineProvider>
    </QueryClientProvider>
  )
}
